.GameIdeas {
  background: url("/assets/images/flame-bg.svg") no-repeat bottom right /
      contain,
    #f7f5f0;
  padding: 10rem 0 6rem 0;
  overflow: hidden;

  .wrapper {
    display: flex;
    align-items: center;
    gap: 5rem;

    .heading {
      margin-bottom: 2rem;

      .sub-text {
        color: #f15a29;
      }

      h2 {
        font-size: 56px;
      }
    }

    .accordion-container {
      position: relative;
      margin-bottom: 1rem;

      .accordion {
        position: relative;
        cursor: pointer;

        h3 {
          color: #f15a29;
          // font-size: 5vw;
        }

        p {
          margin: 0;
        }

        &::before {
          content: "";
          position: absolute;
          top: 12px;
          left: -30px;
          width: 10px;
          height: 10px;
          border: solid 2px #f15a29;
          border-radius: 100px;
          background: #fff;
        }
      }

      &.active:not(:last-child)::before {
        content: "";
        position: absolute;
        top: 15px;
        left: -24px;
        width: 2px;
        height: calc(100% + 15px);
        background: #f15a29;
      }

      &.active {
        .accordion {
          &::before {
            background: #f15a29;
          }
        }
      }
    }

    .cards {
      position: relative;
      display: flex;
      justify-content: center;

      img {
        max-width: 300px;
        transition: 1s;

        &:nth-child(2) {
          position: absolute;
          top: 0;
          left: 54%;
          transform: translateX(-50%);
        }
      }

      &.animate {
        img {
          &:nth-child(1) {
            transform: translate(-100px, -100px);
          }

          &:nth-child(2) {
            transform: translateY(100px);
          }
        }
      }
    }

    .half {
      &:nth-child(1) {
        padding-left: 2rem;
      }
    }
  }

  .bottom {
    text-align: center;
    margin-top: 8rem;

    p {
      font-size: 24px;
      font-weight: bold;
      max-width: 1100px;
      margin: auto;
    }
  }
}


@media (max-width: 1024px){
  
}

@media (max-width: 900px) {
  .GameIdeas {
    .wrapper {
      flex-direction: column-reverse;

      .half {
        &:nth-child(2) {
          width: 50%;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .GameIdeas {
    .wrapper {
      .half {
        &:nth-child(2) {
          width: 100%;
        }
      }
    }
  }
}
