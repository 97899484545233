.Home {
  .Intro {
    padding: 6rem 0;
    display: flex;
    justify-content: center;
    background: #f9f7f3;
    background: url("/assets/images/flames-bg.png") center / cover;

    .wrapper {
      max-width: 1050px;
      display: flex;
      flex-direction: column;
      gap: 3rem;
      align-items: center;

      .top {
        text-align: center;

        h2 {
          font-size: 52px;
          color: #c84b22;
          max-width: 950px;
        }

        p {
          font-size: 22px;
          line-height: 40px;
        }
      }

      .middle {
        max-width: 750px;

       

        ul {
          line-height: 1.8rem;
        }
      }

      .bottom {
        text-align: center;

        h1 {
          font-size: 55px;
          color: #c84b22;
          // width: 100%;
        }
      }
    }
  }

  .message {
    padding: 6rem 0;
    text-align: center;

    .wrapper {
      max-width: 1100px;

      .h4 {
        font-size: 33px;
      }
    }
  }
}

@media (max-width: 600px) {
  .Home {
    .Intro {
      .wrapper {
        .top {
          h2 {
            font-size: 44px;
          }
        }
      }
    }
  }
}
