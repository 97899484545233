.About {
  .Intro {
    padding: 6rem 0;
    display: flex;
    justify-content: center;
    background: #f9f7f3;
    background: url("/assets/images/flames-bg-2.png") no-repeat bottom center /
      cover;

    .wrapper {
      .top {
        h2 {
          font-size: 62px;
          color: #c84b22;
        }

        p {
          font-size: 24px;
          margin-top: 0.6rem;
        }
      }

      .bottom {
        h4 {
          color: #c84b22;
        }

        .highlighted-txt {
          color: #c84b22;
        }
      }
    }
  }

  .message {
    padding: 6rem 0;
    text-align: center;

    .wrapper {
      h3 {
        font-size: 58px;
        color: #c84b22;
      }

      p {
        line-height: 2rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .About {
    .Intro {
      .wrapper {
        .top {
          h2 {
            font-size: 44px;
          }
        }
      }
    }
  }
}
