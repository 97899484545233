.hotterDiv{
    /* padding: 10px; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* width: 1000px; */
    /* margin-right: 150px; */
    /* background-color: red; */
    /* position: relative; */
    margin-bottom: 15vw;
    margin-top: -20vw;
}
.hotterImg{
    width: 30vw;
    margin-left: 14vw;
    margin-top: 2vw
    /* position: absolute; */
    /* left: 50px */
}
.hotterImg1{
    width: 30vw;
    /* position: absolute; */
    margin-right: 20vw;
    /* position: absolute; */
    /* margin-bottom:20vw ; */
}