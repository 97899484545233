.Header {
  padding: 1rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  transition: 0.3s;
  z-index: 10;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      max-width: 90px;
      transition: 0.3s;
      position: relative;
      width: 10%;

      .tagline {
        position: absolute;
        top: 0px;
        left: 87%;
        white-space: nowrap;
        letter-spacing: 1px;

        p {
          color: #fff;
        }
      }
    }

    nav {
      // position: absolute;
      // left: 50%;
      // transform: translateX(-50%);
      display: flex;
      gap: 4rem;

      a {
        color: #fff;
        text-transform: uppercase;
        font-size: small;
        font-weight: bold;

        &:hover,
        &.active {
          border-bottom: solid 2px #fff;
        }
      }
    }
  }

  &.sticky {
    background: #fff;
    padding: 0.5rem 0;
    box-shadow: 0 1px 20px 0px #00000014;

    .wrapper {
      .logo {
        max-width: 70px;

        .tagline {
          p {
            color: unset;
            font-size: small;
          }
        }
      }

      nav {
        a {
          color: #000;

          &:hover,
          &.active {
            border-bottom: solid 2px #000;
          }
        }
      }
    }
  }
}

@media (max-width: 999px) {
  .Header {
    .wrapper {
      .logo {
        .tagline {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .Header {
    .wrapper {
      nav {
        position: absolute;
        top: 100px;
        flex-direction: column;
        background: #fff;
        width: 83%;
        gap: 2rem;
        height: 0;
        padding: 0;
        overflow: hidden;
        box-shadow: 0 1px 20px 0px #00000014;

        a {
          color: #000;

          &.active {
            border-bottom: solid 2px #000;
          }
        }

        &.active {
          height: auto;
          padding: 2rem 1rem;
        }
      }

      .mobile-menu {
        i {
          color: #fff;
          font-size: 1.4rem;
        }
      }
    }

    &.sticky {
      .mobile-menu {
        i {
          color: #000;
        }
      }
    }
  }
}
