.Footer {
  text-align: center;
  background: url("/assets/images/footer-bg.png") no-repeat center bottom /
    contain;
  padding: 5rem 0 0.5rem 0;
  // border: 1px solid red;

  .logo {
    max-width: 200px;
    margin: auto;
    margin-top: 2rem;
  }

  .inquiry-text {
    max-width: 800px;
    margin: 3rem auto;
    line-height: 30px;

    width: 80%;


    p {
      font-size: 22px;

      a {
        display: inline-block;
      }
    }
  }

  .disclaimer-text{
    max-width: 800px;
    margin: 3rem auto;
    line-height: 22px;
    width: 80%;

    h3 {
      font-size: 25px;
    }

    p {
      font-size: 15px;
    }
  }

  .copyright-text {
    border-top: solid 1px #111;
  }
}
