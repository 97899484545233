.DiscoverActionCards {
  padding: 6rem 0;
  background: #f9f7f3;

  .wrapper {
    display: flex;
    gap: 5rem;

    &.desktop-v {
      .sticky-images {
        position: sticky;
        top: 100px;

        img {
          opacity: 0;
          transition: 0.3s;

          &.active {
            opacity: 1;
          }

          &:not(:first-of-type) {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      .content {
        min-height: 100vh; // has to be 100vh or it would activate 2 images at the same time
      }
    }

    &.mobile-v {
      flex-direction: column;

      .row {
        display: flex;
        gap: 5rem;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .heading {
        margin-bottom: 1rem;

        .sub-text {
          color: #f15a29;
        }

        h2 {
          font-size: 58px;
          line-height: 65px;
        }
      }
    }

    .separator {
      max-width: 150px;
    }
  }
}

@media (max-width: 900px) {
  .DiscoverActionCards {
    .wrapper {
      flex-direction: column;

      .half {
        &:nth-child(1) {
          width: 50%;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .DiscoverActionCards {
    .wrapper {
      .half {
        &:nth-child(1) {
          width: 100%;
        }
      }

      .content {
        .heading {
          h2 {
            font-size: 44px;
          }
        }
      }

      &.mobile-v {
        .row {
          flex-direction: column;
        }
      }
    }
  }
}
