.Hero {
  .parallax {
    position: relative;
    // margin-bottom: 6rem;

    .parallax-images {
      position: relative;
      overflow: hidden;

      img {
        transition: 0.5s;

        &:not(:nth-child(1)) {
          position: absolute;
          bottom: 0;
          left: 0;
        }

        &:last-of-type {
          margin-bottom: -8%;
        }

        &.toAndFro {
          &.bombs {
            animation: toAndFro 1s infinite;
            animation-delay: 0.5s;
          }

          &.fire {
            animation: toAndFro 1s infinite;
          }
        }
      }

      .layer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        p {
          position: absolute;
          top: 3%;
          left: 14.5%;
          color: #fff;
          letter-spacing: 1px;
        }
      }
    }

    .parallax-images-bottom-border {
      position: absolute;
      bottom: -12%;
      left: 0;
      width: 100%;

      img {
        &:not(:nth-child(1)) {
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}

// @media (max-width: 600px) {
//   .Hero {
//     display: none;
//   }
// }
