* {
  box-sizing: border-box;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  // font-family: "Open Sans", sans-serif;
  font-family: "Average Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h4 span,
h5,
h6 {
  font-family: "Bebas Neue", sans-serif;
  font-weight: normal;
  letter-spacing: 3.5px;
}

h1,
h2 {
  font-size: 90px;
  margin: 0;
}

h3 {
  font-size: 34px;
  margin: 0;
}

h4 {
  font-size: 25px;
  margin: 0;
}

a {
  text-decoration: none;
}

p,
li {
  color: rgba(17, 17, 17, 0.7);
}

img {
  width: 100%;
}

a {
  color: #c84b22;
}

.img-div {
  line-height: 0;
}

.wrapper {
  max-width: 1300px;
  margin: auto;
  padding: 0rem 2rem;
}

.half {
  width: 50%;
}

.sub-text {
  font-family: "Bebas Neue", sans-serif;
  font-size: 30px;
  margin: 0;
  letter-spacing: 2.3px;
}

.accent-color {
  color: #f15a29;
}

.baseValue {
  vertical-align: sub;
  font-size: small;
}

.superValue {
  vertical-align: super;
  font-size: small;
}

@media (max-width: 900px) {
  h1,
  h2 {
    font-size: 60px;
  }

  .sub-text {
    font-size: 26px;
  }

  .half {
    width: 100%;
  }
}

@media (max-width: 600px) {
  h2 {
    font-size: 44px;
  }
}

// keyframes

@keyframes toAndFro {
  0% {
    left: -0.5rem;
  }
  50% {
    left: 0.5rem;
  }
  100% {
    left: -0.5rem;
  }
}
